import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { white } from '../../design-system/variables/colors';
import { spaceBase, spaceLarge } from '../../design-system/variables/spaces';
import { md } from '../../design-system/media-queries';
import { Icon } from '../Icon';

const GlobalStyle = createGlobalStyle`
 .responsive-footer__social-icons-container {
	    width: 100%;
		max-width: 217px;
		margin: 0 auto ${spaceLarge} auto;

		${md`
			margin-bottom: ${spaceBase};
		`}

		.row {
			flex-wrap: nowrap;
		}
	}

 .responsive-footer__social-icons {
		padding: 0;
 }

	.responsive-footer__social-icon {
		display: block;
		fill: ${white};
		line-height: 0;
	}
`;

export const SocialMedia: React.FC = () => (
	<div className='col-xs-12'>
		<GlobalStyle />
		<div className='responsive-footer__social-icons-container'>
			<div className='row center-xs'>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.facebook.com/Inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='facebook' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://twitter.com/inspirato'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='twitter' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.youtube.com/user/Inspiratodotcom'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='youtube' iconSize='md' />
					</a>
				</div>
				<div className='col-xs responsive-footer__social-icons'>
					<a
						className='responsive-footer__social-icon'
						href='https://www.instagram.com/inspirato/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<Icon iconName='instagram' iconSize='md' />
					</a>
				</div>
			</div>
		</div>
	</div>
);
